.form-buttons {
  padding-top: 10px;
}

.resource-detail-content {
  margin: 25px;
}

.description-format {
  white-space: pre-wrap;
  margin-top: 0;
}

.description-format .format-text {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.resource-detail-row {
  margin-bottom: 10px;
}

.resource-detail-row .resource-detail-label {
  color: #423e39;
  padding-right: 10px;
  font-weight: 600;
}

h1 {
  color: #423e39;
}

.resource-options {
  grid-template-columns: 12fr 1fr;
  display: grid;
}

.remove-resource-option {
  place-self: center;
  margin-top: 0;
  margin-bottom: 25px;
  padding-top: 0;
}

.resource-option {
  grid-template-columns: 1fr 80% 1fr;
  align-items: center;
  gap: 10px;
  display: grid;
}

.resource-option-icon {
  color: #77263c;
  margin-bottom: 25px;
  font-size: 16px;
}

.header {
  background-color: #fff;
  grid-template-columns: 1.5fr 1fr 3fr 1.5fr 1fr 1fr;
  grid-template-areas: ". title . button dropdown logout";
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  margin-bottom: 2px;
  padding: 0 20px 5px;
  display: grid;
  box-shadow: 0 4px 2px -2px #f3f3f3;
}

.header h1 {
  grid-area: title;
  padding-top: 7px;
  font-size: 1.5rem;
}

.header .header-button {
  grid-area: button;
  justify-self: end;
  padding-top: 7px;
}

.header .header-dropdown {
  grid-area: dropdown;
  margin-left: 10px;
  padding-top: 3px;
}

.header .header-logout {
  grid-area: logout;
  justify-self: center;
}

.side-menu-drawer-handle {
  background-color: #fff;
  border: 1px solid #f3f3f3;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 35px;
  margin-top: 15px;
  display: flex;
  box-shadow: 4px 4px 2px -2px #f3f3f3;
}

.side-menu-drawer-handle:hover {
  cursor: pointer;
  color: #77263c;
  background-color: #b8adae;
}

.logo {
  width: 150px;
  position: fixed;
  top: 13px;
  left: 20px;
}

body {
  background-color: #f3f3f3;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow: hidden;
}

h2 {
  margin-top: 0;
}

.app-frame {
  height: calc(100vh - 75px);
  display: flex;
  position: relative;
}

.content {
  width: 100%;
  margin-right: 17px;
  overflow-y: auto;
}

.content-heading {
  justify-content: space-between;
  align-items: center;
  padding: 0 25px 25px;
}

.site-tag-plus {
  background: #fff;
  border-style: dashed;
}

.edit-tag {
  -webkit-user-select: none;
  user-select: none;
}

.tag-input {
  vertical-align: top;
  width: 78px;
  margin-right: 8px;
}

.ant-picker-input > input {
  text-align: center;
}

.table-row-light {
  background-color: #fdfdfd;
}

.table-row-dark {
  background-color: #f0f0f0;
}

.padding-top-2 {
  padding-top: 2vh;
}

.padding-bottom-2 {
  padding-bottom: 2vh;
}

.padding-bottom-4 {
  padding-bottom: 4vh;
}

.padding-2 {
  padding: 2vh;
}

.padding-right-5 {
  offset: revert;
  right: 10vh;
}

.padding-top-40 {
  padding-top: 40px;
}

.flex {
  display: flex;
}

.gap-1 {
  gap: 1vh;
}

.justify-center {
  justify-content: center;
}

.align-center {
  text-align: center;
  align-content: center;
  align-items: center;
}

.ant-descriptions-view table {
  margin: auto;
  width: unset !important;
}

.text-primary-color {
  color: #77263c;
}

.text-danger {
  color: red;
}

.text-invisible {
  color: #0000;
}

.form-text {
  margin-left: 8px;
}

.add-link {
  all: unset;
  cursor: pointer;
  text-align: center;
  background-color: #fff;
  border: 1px dashed #77263c;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 12px;
  padding: 8px 0;
  display: block;
  box-shadow: 0 4px 2px -2px #0000003d;
}

.add-link:hover {
  color: #77263c;
  border: 1px solid #77263c;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ant-tag.certificate-status-tag-lightgray {
  color: #3c3c3c;
  background-color: #d3d3d3;
  border-color: #646464;
}

.ant-tag.certificate-status-tag-gold {
  color: #d48806;
  background: #fffbe6;
  border-color: #ffe58f;
}

.ant-tag.certificate-status-tag-green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.ant-tag.certificate-status-tag-red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}

/*# sourceMappingURL=index.ccaa342e.css.map */
