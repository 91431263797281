.side-menu-drawer-handle {
    background-color: #ffffff;
    border: 1px solid #f3f3f3;
    box-shadow: 4px 4px 2px -2px #f3f3f3;
    width: 15px;
    height: 35px;
    margin-top: 15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
        color: #77263c;
        background-color: #b8adae;
    }
}

.logo {
    width: 150px;
    position: fixed;
    top: 13px;
    left: 20px;
}
