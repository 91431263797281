.header {
    display: grid;
    column-gap: 10px;
    grid-template-columns: 1.5fr 1fr 3fr 1.5fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px;
    padding: 0 20px 5px 20px;
    width: 100%;
    background-color: white;
    box-shadow: 0 4px 2px -2px #f3f3f3;

    grid-template-areas: '. title . button dropdown logout';

    h1 {
        font-size: 1.5rem;
        padding-top: 7px;
        grid-area: title;
    }

    .header-button {
        justify-self: end;
        padding-top: 7px;
        grid-area: button;
    }

    .header-dropdown {
        margin-left: 10px;
        padding-top: 3px;
        grid-area: dropdown;
    }

    .header-logout {
        grid-area: logout;
        justify-self: center;
    }
}
