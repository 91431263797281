body {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    margin: 0;
    overflow: hidden;
    background-color: #f3f3f3;
}

h2 {
    margin-top: 0;
}

.app-frame {
    display: flex;
    height: calc(100vh - 75px);
    position: relative;
}

.content {
    overflow-y: auto;
    margin-right: 17px;
    width: 100%;
}

.content-heading {
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    padding-top: 0;
}

.site-tag-plus {
    background: #fff;
    border-style: dashed;
}

.edit-tag {
    user-select: none;
}

.tag-input {
    width: 78px;
    margin-right: 8px;
    vertical-align: top;
}

.ant-picker-input > input {
    text-align: center;
}

.table-row-light {
    background-color: #fdfdfd;
}

.table-row-dark {
    background-color: #f0f0f0;
}

.padding-top-2 {
    padding-top: 2vh;
}

.padding-bottom-2 {
    padding-bottom: 2vh;
}

.padding-bottom-4 {
    padding-bottom: 4vh;
}

.padding-2 {
    padding: 2vh;
}

.padding-right-5 {
    offset: revert;
    right: 10vh;
}

.padding-top-40 {
    padding-top: 40px;
}

.flex {
    display: flex;
}

.gap-1 {
    gap: 1vh;
}

.justify-center {
    justify-content: center;
}

.align-center {
    text-align: center;
    align-content: center;
    align-items: center;
}

.ant-descriptions-view table {
    width: unset !important;
    margin: auto;
}

.text-primary-color {
    color: #77263c;
}

.text-danger {
    color: red;
}

.text-invisible {
    color: transparent;
}

.form-text {
    margin-left: 8px;
}

.add-link {
    all: unset;
    display: block;
    width: 100%;
    cursor: pointer;
    text-align: center;
    padding: 8px 0;
    margin-bottom: 12px;
    border: 1px dashed #77263c;
    border-radius: 5px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0 4px 2px -2px;

    &:hover {
        border: 1px solid #77263c;
        color: #77263c;
    }
}

// Hide scrollbar for Chrome, Safari and Opera
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

// Hide scrollbar for IE, Edge and Firefox
.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.ant-tag.certificate-status-tag-lightgray {
    color: rgb(60, 60, 60);
    background-color: lightgray;
    border-color: rgb(100, 100, 100);
}

.ant-tag.certificate-status-tag-gold {
    color: #d48806;
    background: #fffbe6;
    border-color: #ffe58f;
}

.ant-tag.certificate-status-tag-green {
    color: #389e0d;
    background: #f6ffed;
    border-color: #b7eb8f;
}

.ant-tag.certificate-status-tag-red {
    color: #cf1322;
    background: #fff1f0;
    border-color: #ffa39e;
}