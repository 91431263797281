.resource-options {
    display: grid;
    grid-template-columns: 12fr 1fr;
}

.remove-resource-option {
    align-self: center;
    justify-self: center;
    margin-bottom: 25px;
    padding-top: 0;
    margin-top: 0;
}

.resource-option {
    display: grid;
    grid-template-columns: 1fr max(80%) 1fr;
    align-items: center;
    gap: 10px;
}

.resource-option-icon {
    font-size: 16px;
    color: #77263c;
    margin-bottom: 25px;
}
